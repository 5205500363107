<template>
  <div
    class="tile-sml pointer p-20 white hover-shadow"
    @mouseenter="isHovered = true"
    @mouseleave="isHovered = false"
    :class="{ hovered: isHovered }"
    @click="handleSmalltTileClick(tileName)"
  >
    <div class="tile__name justify-left pb-5">
      {{ tileName.replaceAll("-", " ") }}
    </div>
    <!-- <img :alt="tileName" class="small-tile__icon" /> -->
    <div>
      <img
        :src="require(`../assets/images/icons/${tileName}.png`)"
        :alt="tileName"
        class="small-tile__icon"
        @click="handleSmalltTileClick(tileName)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "SmallTile",
  props: ["route", "tileName"],
  data: function() {
    return {
      isHovered: false,
    };
  },
  methods: {
    handleSmalltTileClick: function(component) {
      // @TODO- Make the routing global, not just for building blocks
      this.$router.replace(`/design-system/building-blocks/${this.route}/${component}`);
    },
  },
};
</script>
